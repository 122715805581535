* {margin: 0; padding: 0;}

@import 'fonts';

/*
CL: #004671
QL: #0065A4
CL_PR: #fe5000
$primary:       #005681;
$secondary:     #65bfce;

$primary-cl: #005681;
$secondary-cl: #65bfce;
$primary-cl-pr: #fe5000;
*/
$font-stack-cl: 'Muli',Helvetica,Arial,Lucida,sans-serif;

$grey: #333;
$light-grey: #999;
$login-background: #ececec;
$white: #fff;

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

html,body{height:100%}
html>body #wrapper{height:auto; min-height:100%;}

body {
    margin: 0 0 20px;
    padding: 0;
    text-align: left;
    border: none;
    /*background-color: $login-background;*/
    line-height: 1.4;

    .navbar {   
        min-height: 100px;
        height: 100px;
        padding: 0;
        box-shadow: 0 1px 3px 0 #bfbfbf;

        .link-bar {
            min-height: 65px;
            height: 65px;
            margin-top: 35px;
            padding: 0 15px 0 15px;

            .navbar-nav {
                /*background-color: $white;*/
                padding: 0 1em 0 1em;
                text-align: right;

                .nav-item {

                    a {
                        font-size: 0.9em;
                        font-weight: 700;
                        color: $primary;
                    }
                }
                .active {
                    a {
                        font-size: 0.9em;
                        font-weight: 700;
                        color: $secondary;
                    }
                }
            }
            
        }

    }
}

#wrapper {
    height: 100%;
    min-height: 100%;
    width: 100%;
    padding: 1em 1em 1em 1em;
    margin-top: 100px;
    /*background-color: $login-background;*/
}

.login-wrapper {
    margin: 45px auto 20px auto;
    text-align: center;
    width: 100%;
    max-width: 988px;

    .footer {
        margin: 20px auto;
        font-size: 80%;
        text-align: center;
        a {
            text-decoration: underline;
        }
    }
}
.login-logo {
    width: 200px;
    padding-bottom: 2em;
}
.balloon {
    position: relative;
    max-width: 380px;
    margin: 0 auto;
    background: #fff;
    padding: 2em;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 #bfbfbf;
    text-align: left;
    label {
        margin-bottom: 2px;
        font-weight: bold;
        text-align: left;
        text-transform: none;
        font-size: 17px;  
        display: block;  
    }   
}

.card {
    margin-bottom: 1em;
}

h2 {
    font-size: 1.3em;
    font-style: normal;       
}

.bold {
        font-weight: 600;
        font-style: bold; 
}
.login-submit {
    width: 100%;
}
.login-input {
    background-color: $login-background;

    @include placeholder {
        color: $light-grey;
    }
}

.txt-left {
    text-align: left;
}
p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

ul.list-bulleted {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;    
    margin: 0;
    padding: 0 0 0 1.5em;
    list-style: disc;
}
.qrcode {
    table {
        margin: 0 auto;
        border-width: 0;
        border-style: none;
        border-color: #0000ff;
        border-collapse: collapse;

        td {
            border-left: solid 5px #000;
            padding: 0; 
            margin: 0; 
            width: 0px; 
            height: 5px; 
            

        }
        td.black { border-color: #000; }
        td.white { border-color: #fff; }
    }
}

body.login {
    background-color: $login-background; 

    h1.h1_login {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.2;
        margin: 0 0 15px;
        text-align: center;
        color: #333;
    }   
}

body {
    font-family: $font-stack-cl;
    font-weight: 300;
    font-style: normal;

    h1 {
        font-weight: 700;
    }
    h2 {
        padding-bottom: 0.2em; 
    }
    h3 {
        font-size: 1.3em;      
    }
    h4,h5,h6 {
        /*font-weight: normal;*/
    }

    strong {
        font-weight: 700;
    }
    #topline {
        background-color: $primary;
        position: fixed;
        height: 35px;
        top: 0;
        margin: 0;
        padding: 0;
        min-width: 100%;
        width: 100%;

        a {
            font-weight: 700;
            color: $white;
            text-decoration: none;
        }
        a:focus {
            text-decoration: underline;
        }

        .wrapper {
            height: 100%;
            min-height: 100%;
            width: 100%;
            padding: 0 1em 0 1em;
            text-align: right;
        }
    }    
}

body.cl {
    h1 {
        color: $primary; 
    }       
    h2 {
        color: $primary; 
    }      
}
body.cl_pr {
    h1 {
        color: $grey;
    }        
}

body .inline-table-header {
    font-family: $font-stack-cl;
    font-weight: 700;
    font-style: normal;  
    font-size: 0.75em;
}

ol.breadcrumb li {
    /*margin-left: 0em;*/
}

.breadcrumb {
  /*padding: 8px 10px; 
  background-color: transparent;*/
}

.logo {
    padding: 10px 15px;
}

.clear {
    clear:both;
}

table.table {
    padding-left: 1em;
    padding-right: 1em;
}

.outside .table {
    margin-bottom: 0px;
}

ul.pretty-list {
    list-style-type: none;
    margin-left: 2em;
}

.error ul {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
}

div.field_with_errors input, div.field_with_errors select, div.field_with_errors textarea {
    background-color: #F2DEDE;
}

.card-list li {
    margin-left:1.5em;
    padding-bottom:0.2em;
}

hr {
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;

    .push-ends {
        margin-top: 1.25em !important;
        margin-bottom: 1.25em !important;
    }
}

div.padded {
    padding: 0.75em;
}
div.padded-vertical {
    padding: 0.75em 0;
}
div.padded-horizontal {
    padding: 0 8px;
}

.bold p {
    margin-top: 0;
}

.cursor-pointer {
    cursor: pointer;
}

body.cl_pr .form-control:focus {
  border-color: $primary;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(254, 80, 0, 0.6);
}

div.task-note .task-note-header {
    border-bottom: 1px solid #e5e5e5;
    font-size: smaller;
    font-style: italic;
    color: #666;
}

div.task-note .task-note-body {
    padding: 2px 0 10px 0;
}

.black-swan {
    border: 2px solid #333;
}

.odd {
  background-color:#f5f5f5;
}
.errortext {
  color:#b94a48;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 0 0;
    border-radius: 0;
}
.pagination>li {
    display: inline;
}

.pagination>li>a, .pagination>li>span
{
    position: relative;
float: left;
padding: 6px 8px;
line-height: 1.42857143;
text-decoration: none;
color: #004671;
background-color: #fff;
border: 1px solid #ddd;
margin-left: -1px;
}

.pagination>.active>span
{
    z-index: 2;
color: #fff;
/*background-color: #004671;
border-color: #004671;*/
cursor: default;
}

.pagination>.disabled>span
{
    color: #999;
background-color: #fff;
border-color: #ddd;
cursor: not-allowed;
}

.spinner {
    display: none;
    position: relative;
    margin: 0;
    padding: 0;
    /*width: 30px;
    height: 20px;*/
}

/* AUTOCOMPLETE */
ul.ui-autocomplete {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  border: solid 1px #ccc;
  cursor: default;
  z-index: 1100;
} 
ul.ui-widget-content {
    background:none;
}
ul.ui-autocomplete li.ui-menu-item {
    background: none;
    color: #fff;
    font-weight: normal; 
}
body.cl ul.ui-autocomplete li.ui-menu-item {
    background-color: #004671;
    border: 1px solid #004671;
}
body.cl_pr ul.ui-autocomplete li.ui-menu-item  {
    background-color: #0065A4;
    border: 1px solid #0065A4;
}
ul.ui-autocomplete li.ui-menu-item a {
  color: #fff;
  display: block;
  padding: 3px;
}
ul.ui-autocomplete li.ui-menu-item a:hover, 
ul.ui-autocomplete li.ui-menu-item a:active, 
ul.ui-autocomplete li.ui-menu-item a:visited, 
ul.ui-autocomplete li.ui-menu-item a:link,
ul.ui-autocomplete li.ui-menu-item a.ui-state-hover,
ul.ui-autocomplete li.ui-menu-item a.ui-state-active {
    background: none;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    text-decoration: none;
    background-image: none;
}
body.cl ul.ui-autocomplete li.ui-menu-item a:hover, 
body.cl ul.ui-autocomplete li.ui-menu-item a:active, 
body.cl ul.ui-autocomplete li.ui-menu-item a:visited, 
body.cl ul.ui-autocomplete li.ui-menu-item a:link,
body.cl ul.ui-autocomplete li.ui-menu-item a.ui-state-hover,
body.cl ul.ui-autocomplete li.ui-menu-item a.ui-state-active {
    border: 1px solid #004671;
}

body.cl_pr ul.ui-autocomplete li.ui-menu-item a:hover, 
body.cl_pr ul.ui-autocomplete li.ui-menu-item a:active, 
body.cl_pr ul.ui-autocomplete li.ui-menu-item a:visited, 
body.cl_pr ul.ui-autocomplete li.ui-menu-item a:link,
body.cl_pr ul.ui-autocomplete li.ui-menu-item a.ui-state-hover,
body.cl_pr ul.ui-autocomplete li.ui-menu-item a.ui-state-active {
    border: 1px solid #0065A4;
}

/* Bootstrap notify */
[data-notify="progressbar"] {
    margin-bottom: 0px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 5px;
}

.turbolinks-progress-bar {
  height: 10px;
  background-color: $secondary;
}

label.required {
  color: #b94a48;
}

label {
/*
    margin-bottom: 2px;
    text-transform: uppercase;
    font-size: 85%;  */
    font-weight: 300;
    display: block; 

}

.badge-cocked-pistol {
  background-color: #fff;
  color: #333;
}

.badge-inner-pink {
  background-color: #ffcccc;
  color: #333;
}

.badge-primary-light {
  background-color: #EBF2F6;
  color: #005681;
}

ul.declarations {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.declarations > li { 
    list-style: none;
    position: relative;
    margin-left: 3em; 
    margin-bottom: 8px;
}

ul.declarations > li:before {
    content: "✓"; /* Insert content that looks like bullets */
    position: absolute;
    left: -1.5em;
}

ol.roman {
    counter-reset: list;
    list-style: none;
    padding: 0;
    margin: 0;    
}
ol.roman > li {
    list-style: none;
    position: relative;
    margin-left: 3em; 
    margin-top: 8px;    
}
ol.roman > li:before {
    counter-increment: list;
    content: "(" counter(list, lower-roman) ") ";
    position: absolute;
    left: -1.9em;
}

.item_container {
    padding-bottom: 0.75em;
}

.nubbin {
    /*display: none;*/
    position: relative;
    top: 0px;
    left: 0px;
}

.nubbin_content {
    position: absolute;
    right: 0;
    margin: 0;
    font-size: 11px;
    line-height: 10px;
    /*background-color: #fff;*/

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-border-bottom-left-radius: 26px;
    -webkit-border-top-left-radius: 26px;
    -moz-border-radius-bottomleft: 26px;
    -moz-border-radius-topleft: 26px;
    border-bottom-left-radius: 26px;
    border-top-left-radius: 26px;  
}

.nubbin_content_left {
    position: absolute;
    right: 0;
    margin: 0;
    font-size: 11px;
    line-height: 10px;
    /*background-color: #fff;*/

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
    -webkit-border-bottom-left-radius: 26px;
    -webkit-border-top-left-radius: 26px;
    -moz-border-radius-bottomleft: 26px;
    -moz-border-radius-topleft: 26px;
    border-bottom-left-radius: 26px;
    border-top-left-radius: 26px;   */
}

.item_info {
    display: inline;
}

.totals {

}

.totals-bottom {
    border-bottom: 1px solid #ccc;
    background-color: #fafafa;
}

.totals-top {
    border-top: 1px solid #ccc;
    background-color: #fafafa;
}

.uppercase {
    margin-bottom: 2px;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 85%;  
    display: block;  
}

.larger {
    font-size: 1.1em;
}

.date {
    /*padding-right: 0.8em;*/
}

.toast-container {
    position: sticky;
    z-index: 1055;
    top: 0
}

.toast-wrapper {
    position: absolute;
    z-index: 1055;
    top: 0;
    right: 0;
    margin: 5px
}

.toast-container>.toast-wrapper>.toast {
    min-width: 150px;
    background-color: rgb(255, 255, 255);
    border-top: none;
}

.toast-container>.toast-wrapper>.toast>.toast-header strong {
    padding-right: 20px;
}

/* File upload dropzone */
#dropzone_upload {   
    text-align: center;
    font-size: xx-large;
    color: #fff;
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
    z-index: 1101;
    overflow: auto;
    font-weight: 700; 
}

#dropzone {
    display: none;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 1102;
    -moz-opacity: 0.8;
    opacity:.80;
    filter: alpha(opacity=80);
}

.is-invalid, .is-valid  {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: right 0.5625rem center;
  background-size: 1.125rem;
}    

.valid-backup {
  /* border-color: #28a745; */
  padding-right: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);  
} 

.is-invalid  {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
}  

.is-valid  {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
} 
.modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

/* Highcharts */
.highcharts-container {
    font-family: $font-stack-cl;
    font-weight: 300;
    font-style: normal;
}
.highcharts-background {
    fill: none;
}

.highcharts-credits {
    display: none;
}

.timeline-present.highcharts-data-label path {
    stroke: $primary;
    stroke-width: 2;
}
.timeline-past.highcharts-data-label path {
    stroke: $secondary;
    stroke-width: 1;
}
.timeline-future.highcharts-data-label path {
    stroke: $dark;
    stroke-width: 1;
}

.timeline-present.highcharts-data-label path.highcharts-label-box {
    fill: $primary;
    stroke-width: 2px;
    stroke: $dark;
}

.timeline-present.highcharts-data-label text {
    font-weight: normal;
    fill: white;
}

.timeline-present.highcharts-point {
    fill: $primary;
    stroke: $dark;
}

.timeline-present.highcharts-halo {
    fill: $primary;
    stroke: $dark;
}


.timeline-past.highcharts-data-label path.highcharts-label-box {
    fill: $secondary;
    stroke-width: 1px;
    stroke: $dark;
}

.timeline-past.highcharts-data-label text {
    font-weight: normal;
    fill: white;
}

.timeline-past.highcharts-point {
    fill: $secondary;
    stroke: $dark;
}

.timeline-past.highcharts-halo {
    fill: $secondary;
    stroke: $dark;
}

.timeline-future.highcharts-data-label path.highcharts-label-box {
    fill: $light;
    stroke-width: 1px;
    stroke: $dark;
}

.timeline-future.highcharts-data-label text {
    font-weight: normal;
    fill: $dark;
}

.timeline-future.highcharts-point {
    fill: $light;
    stroke: $dark;
}

.timeline-future.highcharts-halo {
    fill: $light;
    stroke: $dark;
}

.highcharts-tooltip  {
    fill: red;
    stroke: red;
}
.highcharts-tooltip-box  {
    fill: white;
    stroke: white;
    stroke-width: 2px;
    border: 5px red solid;
}
.highcharts-tooltip text {
    fill: $dark;
    stroke: red;
}
.highcharts-tooltip-header {
    fill: orange;
    stroke: red;
}
.dz-message {
    padding: 40px;
    background-color: #ccc;
    border: 2px dashed rgba(0, 0, 0, 0.3);
    min-height: 100px;
}

.dz-drag-hover {
    background-color: black;
    z-index: 1102;
    -moz-opacity: 0.8;
    opacity:.80;
    filter: alpha(opacity=80);
}

.form-control {

background-color: #f9f9f9;
border: 1px solid #dddddd;
border-radius: 2.5px;
/*
padding: 7.5px 7.5px 3.75px;
font-size: 14px;
min-height: 44px;
*/
}