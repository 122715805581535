$primary:       #005681;
$secondary:     #65bfce;
$info: #65bfce;

$theme-colors: (
  "primary": #005681,
  "secondary": #65bfce,
  "success": #198754,
  "info": #0dcaf0,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": #212529,
  "inner-pink": #ffcccc,
  "primary-light": #EBF2F6,
  "cocked-pistol": #FFFFFF,
  "non-compliant": #000000,
  "yoda": #D8F0D2,
  "positive": #005681,
  "negative": #fe2e00
);
$body-bg: #ececec;
$link-decoration: none;
$link-hover-decoration: underline;