$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import "choices.js/public/assets/styles/choices.css";
@import '../src/stylesheets/cl.scss';
@import "~bootstrap/scss/functions"; 
@import "~bootstrap/scss/variables";
@import '~bootstrap/scss/bootstrap';
@import '../src/stylesheets/style.scss';